<template>
    <div class="page-rooter-fff">
        <nav-bar header-title="新店开业特惠"  backColor="#ffffff" :show-back="true" :header-back="newAppBack" />
        <app-page id="box" class="page-img">
            <img  @click="jump(item.jumpUrl)" v-for="(item, index) in list" :key="index" :src="item.img" alt="">
        </app-page>
    </div>
</template>

<script>
import {newAppBack, initBack, gotoCampExclusiveDetail, defaultApp, gotoAppPage} from '@/lib/appMethod';
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';

export default {
    components: {
        navBar,
        appPage,
    },
    mixins: [userMixin],
    data() {
        return {
            list: [
                
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/1.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/2.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/3.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/4.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/5.jpg'},
                {jumpUrl: '1', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/6.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/7.jpg'},
                {jumpUrl: '1', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/8.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/9.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/temp-activity/new-venue-nav/10.jpg'},
            ]
        };
    },
    methods: {
        newAppBack,
        jump(jumpUrl) {
            if(jumpUrl === '1') {
                this.goToBuy()
            }
            
        },
        goToBuy() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/temp-activity/new-venue-mall&userId=1&token=1&teacherId=637304695666286592`,
                })
            } else {
                this.$router.push(`/temp-activity/new-venue-mall?userId=1&token=1&teacherId=637304695666286592`)
            }
        }
    },
    async created() {
        initBack();
        await this.$getUserId();
    },
};
</script>

<style scoped lang="less">
.page-rooter-fff{
    background-color: #F9FAFB;
    .page-img{
        img{
            width: 100%;
            display: block;
        }
    }
}
</style>
