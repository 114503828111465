import { render, staticRenderFns } from "./new-venue-nav.vue?vue&type=template&id=0fb90ec5&scoped=true&"
import script from "./new-venue-nav.vue?vue&type=script&lang=js&"
export * from "./new-venue-nav.vue?vue&type=script&lang=js&"
import style0 from "./new-venue-nav.vue?vue&type=style&index=0&id=0fb90ec5&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb90ec5",
  null
  
)

export default component.exports